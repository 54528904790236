import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

import '../styles/App.css';
import '../styles/Form.css';
import '../styles/toast.css';

import Page from './Page';
import Footer from './Footer';

function App() {
  return (
    <div className="App">
      <ToastContainer />
      <Page />
      <Footer />
    </div>
  );
}

export default App;
