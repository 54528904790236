import React from 'react';
import ReactDOM from 'react-dom/client';

import TagManager from 'react-gtm-module';

import './styles/index.css';
import App from './components/App';

const tagManagerArgs = {
  gtmId: 'GTM-5N7XGP5',
};

TagManager.initialize(tagManagerArgs);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
