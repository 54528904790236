import PropTypes from 'prop-types';

const TextInput = (props) => {
  const {
    required,
    variant,
    label,
    type, name, placeholder, value,
    disabled,
    onChange,

  } = props;

  const variantsMapping = {
    input: 'input',
    textarea: 'textarea',
  };

  const Component = variantsMapping[variant] || 'input';

  return (
    <div className="form-section">
      {label && (
      <label htmlFor={name}>
        {label}
      </label>
      )}
      <Component
        {...(variant === 'input' ? { type } : {})}
        required={required}
        name={name}
        id={name}
        placeholder={placeholder}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
};

TextInput.propTypes = {
  variant: PropTypes.string,
};

TextInput.defaultProps = {
  variant: 'input',
};

export default TextInput;
