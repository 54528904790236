import email from '../assets/images/email.png';
import phone from '../assets/images/phone-call.png';
import '../styles/Footer.css';

function Footer() {
  return (
    <div className="footer">
      <div className="footer-inner">
        <p id="copyrights">Tomorrow Jobs © 2022</p>
        <div>
          <div className="contact">
            <img src={email} alt="pictogramme enveloppe" />
            <p>laurie@tomorrowjobs.fr</p>
          </div>
          <div className="contact">
            <img src={phone} alt="pictogramme téléphone" />
            <p>06 22 48 55 14</p>
          </div>

        </div>
      </div>

    </div>

  );
}

export default Footer;
