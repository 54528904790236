import { useState } from 'react';
import axios from 'axios';

import logo from '../assets/images/logo-tj.svg';
import techicon from '../assets/images/tech-icon.svg';
import rocket from '../assets/images/rocket.png';
import TextInput from './TextInput';

import notify from '../services/notify';

import '../styles/Page.css';

function Page() {
  const [name, setName] = useState('');
  const [prenom, setPrenom] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [linkedin, setLinkedin] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const submitForm = async (e) => {
    e.preventDefault();
    notify('success', 'Vos informations ont été enregistrées avec succès !');
    try {
      setIsLoading(true);
      await axios.post('https://api.remote.tomorrowjobs.fr/form', {
        name,
        firstname: prenom,
        email,
        phone,
        linkedin,
      });
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({ event: 'Sign-up' });
      setName('');
      setPrenom('');
      setEmail('');
      setPhone('');
      setLinkedin('');
    } catch (error) {
      console.error(error);
    }
    setIsLoading(false);
  };

  return (
    <>
      <div className="hero">
        <div className="hero-content">
          <div className="top-hero">
            <img id="logo-tj" src={logo} alt="logo tomorrow jobs" />
            <h1>Remote Jobs</h1>
            <p>
              Pour recevoir les meilleures offres tech en Full Remote
              (salaire de 30K à 100K€ brut annuel),
              remplissez ce court formulaire :
            </p>
          </div>

          <div className="bottom-hero">
            <img id="tech-icon" src={techicon} alt="icone tech" />
            <form onSubmit={submitForm}>
              <TextInput
                required
                label="Nom"
                name="nom"
                placeholder="DUBOIS"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
              <TextInput
                required
                label="Prénom"
                name="prenom"
                placeholder="Jean"
                value={prenom}
                onChange={(e) => setPrenom(e.target.value)}
              />
              <TextInput
                required
                type="email"
                label="Adresse mail"
                name="email"
                placeholder="jean@exemple.com"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <TextInput
                label="Téléphone (optionnel)"
                name="tel"
                placeholder="01 02 03 04 05"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
              <TextInput
                type="url"
                label="Lien vers votre profil LinkedIn (optionnel)"
                name="linkedin"
                placeholder="https://www.linkedin.com/in/jean-dubois/"
                value={linkedin}
                onChange={(e) => setLinkedin(e.target.value)}
              />

              <button type="submit" disabled={isLoading}>Valider</button>

              <img className="rocket" src={rocket} alt="pictogramme fusée" />
            </form>

          </div>

        </div>
      </div>
      <div id="section-text">
        <p>
          Tomorrow Jobs accompagne les
          {' '}
          <span className="font-bold">développeurs </span>
          {' '}
          à trouver les
          {' '}
          <span className="font-bold">meilleures opportunités IT </span>
          du marché depuis 2016. Avec déjà plus de
          {' '}
          <span className="font-bold">300 recrutements </span>
          {' '}
          réalisés,
          <span className="font-bold"> nous vous accompagnons pour trouver les meilleures offres selon vos critères </span>
          (technos, type de structure, rémunération…).
        </p>
      </div>
    </>
  );
}

export default Page;
