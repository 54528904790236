import { toast } from 'react-toastify';

const notify = (type, message) => (
  toast[type](message, {
    theme: 'colored',
    position: 'top-right',
    autoClose: 3000,
  })
);

export default notify;
